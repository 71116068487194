<template>
<div class="row mb-3">
    <div class="col-12">
        <div class="row mb-3">
            <div class="col-lg-12">
                <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                    <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                    <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Announcement </h2>
                </div>
            </div>            
        </div> 
        <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
            <form @submit.prevent="getAnnouncements(page)" class="row">
                <div class="col-lg-9">
                    <div class="form-group icon-input mb-0">
                        <i class="ti-search font-xs text-grey-400"></i>
                        <input v-model="search" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" placeholder="Type here what you are looking for..." />
                    </div>
                </div>
                <div class="col-lg-3">
                    <button type="Submit" class="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase ">Search</button>
                </div>
            </form>
        </div>
        <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
            <div class="row">    
                <div class="col-12" v-if="!loadData">
                    <div v-if="exist" class="row justify-content-center">
                        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <div v-for="(item, index) in announcements.data" :key="index" class="card w-100 p-3 bg-grey p-lg-2 border-0 mb-3 rounded-lg d-block float-left">
                                <router-link :to="'/my-course/'+paramsId+'/announcements/'+item.id" class="float-left mb-0 text-grey-900 fw-700 m-2">{{item.title | truncate(30, '...')}}</router-link>
                                <div class="d-flex flex-column align-items-end float-right mt-5">
                                    <div class="d-flex align-items-center">
                                        <span class="font-xssss fw-600 text-grey-500 d-block mr-1 text-right">Posted On : </span>
                                        <small class="text-right">{{item.created_at | formatDate}}</small>
                                    </div>
                                    <router-link :to="'/my-course/'+paramsId+'/announcements/'+item.id" class="btn btn-current mt-2">See More</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 my-4">
                            <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                                <li v-for="(link, index) in announcements.links" :key="index" class="page-item m-1">
                                    <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                                    <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                                    <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                </div>  
                <div class="col-12" v-else>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                            <div class="my-3">
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div> 
   
</template>

<script>
import axios from 'axios'
export default {
    name: `MyCourseAnnouncements`,
    data(){
        return {
            announcements: [],
            paramsId: this.$route.params.idCourse,
            exist: true,
            loadData: true,
            page: 1,
            search: ''
        }
    },
    created() {
        this.getAnnouncements(this.page)
    },
    methods: {
        async getAnnouncements(page){
            this.exist = true
            await axios.get(`${process.env.VUE_APP_URL_API}/core/mycourse/announcement?slug=${this.paramsId}&page=${page}&search=${this.search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data.total == 0) {
                    this.exist = false
                } else {
                    this.exist = true
                    this.announcements = res.data
                }
            }).catch((err) => {
                this.loadData = false
                if (err.response.status === 404) {
                     this.exist = false
                }
            })
        },
        changeCourse(page) {
            this.page = parseInt(page)
            this.getAnnouncements(this.page)
        },
        prevCourse() {
            if (this.page >= 1) {
                this.page -= 1
                this.getAnnouncements(this.page)
            }
        },
        nextCourse() {
            if (this.announcements.last_page > this.page) {
                this.page += 1
                this.getAnnouncements(this.page)
            }
        },
    }
}
</script>